.EssayForm {
  text-align: center;
}


.EssayForm-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.EssayForm-row {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
  justify-content: center;
  color: white;
}
.EssayResults h2 {
  font-size: calc(4px + 2vmin);
  color: white;
}
.EssayResults li {
  text-align: left;
  list-style: none;
  font-size: 1em;
  color: white;
}
.EssayForm-form {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
}

.EssayForm-link {
  color: #61dafb;
}


